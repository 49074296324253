(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CommodityEditController', CommodityEditController);

    CommodityEditController.$inject = ['$scope', 'Principal', '$q', 'LoginService', '$state','User','MessageService','ProductCategory', 'ParseLinks',
    '$uibModal', 'Items', 'UnitMeasurement', 'Depository', 'Supplier', 'Makeup', 'Product', 'Sorting', 'entity', '$stateParams',
    '$timeout', 'MaterialTemplates', 'Qinius'];

    function CommodityEditController ($scope, Principal, $q, LoginService, $state,User,MessageService, ProductCategory, ParseLinks,
        $uibModal, Items, UnitMeasurement, Depository, Supplier, Makeup, Product, Sorting, entity, $stateParams, $timeout, MaterialTemplates, Qinius) {
        var vm = this;
        vm.data = entity;

        //商品类型
        vm.commodityTypes =[
            {name:'定制商品',id:'OrderingProduct'},
            {name:'标准印品',id:'StandardProduct'},
            {name:'标准通品',id:'GeneralProduct'}
        ];

        vm.superiorName = null;
        //计量单位
        vm.unitList = UnitMeasurement.getAll();
        //商品品类
        vm.productCategoryList = ProductCategory.getAll();
       //生产商
        vm.supplierList=Supplier.getAll({});
        $q.all([vm.data.$promise,vm.productCategoryList.$promise ]).then(function(){
            //物料模板
            MaterialTemplates.getAll({type:vm.data.category != 'MaterialProduct' ? 'product' : 'accessories'},function(res){
                vm.materialTemplatesList = res.filter(function(item){return item.enable;})
            });
            if (vm.data.id) {
                for (var i = 0; i < vm.productCategoryList.length; i++) {
                    if (vm.productCategoryList[i].id == vm.data.productCategoryId && vm.productCategoryList[i].superiorId != "") {
                        vm.superiorName = vm.productCategoryList[i].superiorName;
                        break
                    }
                }
                vm.data.propertyConfigs.sort(function(a, b) {
                    return a.sort - b.sort
                })
                //辅料配置开关
                vm.data.ingredientsEnable = (vm.data.materialConfigs.length > 0 || !vm.data.materialConfigs ? true : false);
            }
            //是否定制
            vm.isCustomization = (vm.data.category == 'GeneralProduct' ? false : true);
        });

        //商品类型选择
        $scope.commodityTypeSelect = function(){
            if(vm.data.category == 'GeneralProduct'){
                vm.isCustomization = false;
                vm.data.producible = false;
                vm.data.autoOutsourcing = false;
                warningTime();
                autoOutsourcing();
                for(var i=0; i<vm.data.propertyConfigs.length; i++){
                    vm.data.propertyConfigs[i].warehouseConfigs = null;
                }
            }
            if(vm.data.category == 'OrderingProduct' || vm.data.category == 'StandardProduct'){
                vm.isCustomization = true;
            }
        };

        // 附件PDF文件的校验设定
        $scope.pdfFileEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/file-check.html',
                controller: 'FileCheckController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: false, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].filePageCount = backData.filePageCount;
                    vm.data.propertyConfigs[index].fileHeight = backData.fileHeight;
                    vm.data.propertyConfigs[index].fileWidth = backData.fileWidth;
                }
            });
        }

        //物料模板选择
        $scope.productCategorySelect = function(){
            var _number1 = "";
            vm.superiorName = null;
            vm.data.propertyConfigs = [];
            //商品属性
            if(!vm.data.templateId){
                vm.data.items =[];
                vm.data.templateId = '';
                vm.data.templateName = '';
                vm.data.productCategoryName ='';
                vm.data.productCategoryId ='';
            }else{
                for(var j=0;j<vm.materialTemplatesList.length;j++){
                    var _template = vm.materialTemplatesList[j];
                    if(vm.data.templateId == _template.id){
                        vm.data.items = _template.items;
                        vm.data.templateId = _template.id;
                        vm.data.templateName = _template.name;
                        vm.data.productCategoryName = _template.productCategoryName;
                        vm.data.productCategoryId = _template.productCategoryId;
                        for(var i=0; i<vm.productCategoryList.length; i++){
                            if(vm.productCategoryList[i].id == _template.productCategoryId){
                                var _number = "";
                                if(vm.productCategoryList[i].superiorId){
                                    vm.superiorName = vm.productCategoryList[i].superiorName;
                                    for(var j=0; j<vm.productCategoryList.length; j++){
                                        if(vm.productCategoryList[i].superiorId == vm.productCategoryList[j].id){
                                            _number = vm.productCategoryList[j].categoryCode;
                                        }
                                    }
                                }
                                _number1 = _number + '-' + vm.productCategoryList[i].categoryCode;
                                break
                            }
                        }
                        //获取订单编号
                        Product.getNumber2({categoryCode:_number1}, function (res) {
                            vm.data.number = res.number;
                        });
                        break
                    }
                }
            }

        }
        //计量单位选择
        $scope.measurementSelect = function(){
            for(var i=0; i<vm.unitList.length; i++){
                if(vm.unitList[i].id == vm.data.measurementId){
                    vm.data.measurementName = vm.unitList[i].name;
                }
            }
        };

        //价格设置
        // $scope.priceSet = function () {
        //     $uibModal.open({
        //         templateUrl: 'app/product/finished/modal/price.html',
        //         controller: 'PriceController',
        //         controllerAs: 'vm',
        //         backdrop: 'static',
        //         resolve: {
        //             entity: [function() {
        //
        //             }]
        //         }
        //     }).result.then(function (backData) {
        //         if(typeof backData == "object"){
        //             vm.data.price = backData;
        //         }
        //     });
        // }
        //流转配置
        $scope.supplierSelect = function(data){
            for(var i=0; i<vm.supplierList.length; i++){
                if(vm.supplierList[i].id == data.supplierId){
                    data.supplierName = vm.supplierList[i].name;
                }
            }
        };
        $scope.defaultFn = function(index){
            for(var i=0; i<vm.data.productCirculations.length; i++){
                if( i == index){
                    vm.data.productCirculations[i].defaulted = true;
                }else {
                    vm.data.productCirculations[i].defaulted = false;
                }
            }
        };
        $scope.defaultDelete = function(index){
            vm.data.productCirculations.splice(index, 1);
        }
        $scope.productCirculationAdd = function(index){
            var _obj = {
                supplierId: '',
                supplierName: '',
                defaulted: false
            };
            vm.data.productCirculations.push(_obj)
        };

        $scope.rapidGenerating = function () {
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/property.html',
                controller: 'PropertyController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return vm.data.items;
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    var day = new Date();
                    var _backData = [];
                    for( var x=0; x<backData.length; x++){
                        if(backData[x].length > 0){
                            _backData.push(backData[x]);
                        }
                    }
                    var _arr = getCombination(_backData);
                    if(typeof _arr == "object") {
                        for (var i = 0; i < _arr.length; i++) {
                            //每次进行求出最大索引
                            var indexEs=[];
                            angular.forEach(vm.data.propertyConfigs,function(item){
                                indexEs.push(item.number.split('-')[(item.number.split('-')).length-1]);
                            });
                            var _sku = vm.data.name + '-' + _arr[i].name;
                            var _isSku = false;
                            for(var j=0; j<vm.data.propertyConfigs.length; j++){
                                if (vm.data.propertyConfigs[j].cancelled) {
                                    continue;
                                }
                                if(_sku == vm.data.propertyConfigs[j].sku){
                                    _isSku = true;
                                }
                            }
                            if(!_isSku){
                                var maxIndex= indexEs.length ==0 ? 0: Math.max.apply(null, indexEs);
                                var _obj = {
                                    sku: vm.data.name + '-' + _arr[i].name,
                                    skuSeq: _arr[i].number,
                                    number: vm.data.number + '-' + (maxIndex + 1),
                                    createdDate: day,
                                    minStorage: 0,
                                    warehouseConfigs: null
                                };
                                vm.data.propertyConfigs.push(_obj);
                            }
                        }
                    }
                    console.log(vm.data.propertyConfigs)
                }
            });
        }
        $scope.itemEdit = function (){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/product-itmes.html',
                controller: 'ProductItemsController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return angular.copy(vm.data.items);
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    var _isE=true;
                    if(!vm.data.items){vm.data.items=[]};
                    for(var i=0;i<vm.data.items.length;i++){
                        var _isE1 = false;
                        backData.forEach(function (item){
                            if(vm.data.items[i].id == item.id){
                                _isE1 = true;
                            }
                        })
                        if(!_isE1){
                            _isE = false;
                            break
                        }
                    }
                    if(!_isE || vm.data.items.length != backData.length){
                        vm.data.items = backData;
                        vm.data.propertyConfigs = [];
                    }
                }
            });

        }
        function getCombination (array) {
            if(array.length > 0){
                var _arr = [];
                if (array.length < 2) {
                    for(var j =0; j<array[0].length; j++){
                        _arr.push([{
                            name:array[0][j].name,
                            number:array[0][j].number,
                        }])
                    }
                }else {
                    _arr = [].reduce.call(array, function (col, set) {
                        var res = [];
                        col.forEach(function (c) {
                            set.forEach(function (s) {
                                var t = [].concat(Array.isArray(c) ? c : [c]);
                                t.push(s);
                                res.push(t);
                            })
                        });
                        return res;
                    });
                }
                for(var i=0; i<_arr.length; i++){
                    var _h = '';
                    var _n = '';
                    var _arrI = _arr[i].sort();
                    for(var j=0; j<_arrI.length; j++){
                        _h += '-' + _arrI[j].name;
                        _n += '-' + _arrI[j].number;
                    }
                    _arr[i] = {
                        name:_h.substring(1),
                        number:_n.substring(1),
                    }
                }
                return _arr
            }
        }
        vm.name = "";
        $scope.nameFocus = function (){
            vm.isSaving = true;
            vm.name = angular.copy(vm.data.name);
        }
        $scope.nameBlur = function (){
            if(vm.data.propertyConfigs.length>0 && vm.data.name != vm.name){
                MessageService.confirm("商品名称变更，将同步更新sku名称，是否确认变更？", function () {
                    vm.data.propertyConfigs.forEach(function (propertyConfig){
                        propertyConfig.sku = vm.data.name + removeFirstOccurrence(propertyConfig.sku,vm.name)
                    })
                    vm.isSaving = false;
                },function (){
                    vm.data.name = vm.name;
                    vm.isSaving = false;
                });
            }else{
                vm.isSaving = false;
            }
        }

        function removeFirstOccurrence(str, substr) {
            var regex = new RegExp(substr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), '');
            return str.replace(regex, '');
        }
        $scope.nameKeypress = function ($event){
            if ($event.keyCode === 13) {
                document.getElementById('nameId').blur();
            }
        }
        //商品属性删除
        $scope.propertyDelete = function (data, index) {
            if (!!data.id) {
                data.cancelled = true;
            }else{
                vm.data.propertyConfigs.splice(index, 1);
            }
        };

        //设置最小库存预警
        $scope.warningSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: vm.data.category == 'MaterialProduct' ? true : false}
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        }
        $scope.warningEdit = function(data,index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/minWarning.html',
                controller: 'MinWarningController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {type: false, data: data};
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].minStorage = backData.minStorage;
                    vm.data.propertyConfigs[index].warehouseConfigs = backData.warehouseConfigs;
                }
            });
        };
        //辅料设置
        $scope.ingredientsSet = function(index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/ingredients.html',
                controller: 'IngredientsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].materialConfigs = backData;
                }
            });
        };
        $scope.ingredientsEdit = function(data, index){
            $uibModal.open({
                templateUrl: 'app/product/finished/modal/ingredients.html',
                controller: 'IngredientsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return data.materialConfigs
                    }]
                }
            }).result.then(function (backData) {
                if(typeof backData == "object"){
                    vm.data.propertyConfigs[index].materialConfigs = backData;
                }
            });
        };

        //保存
        $scope.save = function () {
            if(vm.isSaving){
                return
            }
            if(!vm.data.name){
                MessageService.error("商品名称不能为空");
                return;
            }
            if(!vm.data.category){
                MessageService.error("商品类型不能为空");
                return;
            }
            if(!vm.data.productCategoryId){
                MessageService.error("商品品类不能为空");
                return;
            }
            if(!vm.data.measurementId){
                MessageService.error("计量单位不能为空");
                return;
            }

            if(vm.data.propertyConfigs.length == 0){
                MessageService.error("属性不能为空");
                return;
            }
            vm.isSaving = true;
            if(!vm.data.id){
                var _data = angular.copy(vm.data);
                for(var i=0; i<_data.propertyConfigs.length; i++){
                    _data.propertyConfigs[i].number = _data.number + '-' + (Number(i)+Number(1));
                    _data.propertyConfigs[i].sort = Number(i) + Number(1);
                }
                Product.save(_data, function (res) {
                    MessageService.success("保存成功");
                    window.history.back();
                }, function (error) {
                    vm.isSaving = false;
                    MessageService.error(error.data.message || "保存失败，请检查是否误删使用中的商品SKU");
                });
            }else{
                Product.update(vm.data, function (res) {
                    console.log(vm.data);
                    MessageService.success("保存成功");
                    window.history.back();
                }, function (error) {
                    vm.isSaving = false;
                    MessageService.error(error.data.message || "保存失败，请检查是否误删使用中的商品SKU");
                });
            }
        }

        //验证sku编号是否重复
        $scope.skuNumberChange = function (e, item,index){
            if(!item.skuNumber){
                vm.isSaving = false;
                return
            }
            for(var i=0;i<vm.data.propertyConfigs.length;i++){
                if(i != index && item.skuNumber == vm.data.propertyConfigs[i].skuNumber){
                    MessageService.error('规格【' + item.sku + "】的sku编号已存在");
                    item.skuNumber="";
                    vm.isSaving = false;
                    return false
                }
            }
        }

        //返回
        $scope.goBack = function () {
            window.history.back();
        }


        //清除方法
        $scope.productionCb = function () {
            if(!vm.data.producible){
                warningTime()
            }
        };
        $scope.autoOutsourcingCb = function () {
            if(!vm.data.autoOutsourcing){
                autoOutsourcing()
            }
        };

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        $q.all([vm.tokens.$promise, vm.domains.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        })

        $scope.removeFile = function () {
            vm.data.previewImg = null;
        };
        window.updateImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('token', vm.token);
                $.ajax({


                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.data.previewImg = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.removeSkuFile = function (data) {
            data.previewImg = null;
        };
        window.updateSkuImg = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var index = $($event.target).attr('index');
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.data.propertyConfigs[index].previewImg = $scope.domain + data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //预警时间
        function warningTime() {
            vm.data.warningTime = "";
        }
        //流转配置
        function autoOutsourcing() {
            vm.data.productCirculations = [];
        }
        $scope.categoryInfo = function (item){
            switch (item) {
                case 'OrderingProduct':
                    return '定制品';
                case 'GeneralProduct':
                    return '成品';
                case 'MaterialProduct':
                    return '辅料';
                default:
                    return item
            }
        }
    }
})();
