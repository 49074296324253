(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('CloseOutBoundDialogController', CloseOutBoundDialogController);

    CloseOutBoundDialogController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder','$uibModalInstance', 'entity', 'Express'];

    function CloseOutBoundDialogController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModalInstance, entity, Express) {
        var vm = this;
        vm.id = entity;
        vm.reason = ''

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };

        //保存
        $scope.save = function () {
            $uibModalInstance.close(vm.reason);
        }
    }
})();
