(function () {
    "use strict";

    var ModeleSelect = {
        restrict: "EAC",
        replace: true,
        transclude: true,
        templateUrl: "app/components/c-select/c-select.html",
        controllerAs: "vm",
        scope: {},
        bindings: {
            ngModel: "=",
            placeholder: "@",
            prefix: "@", //前缀
            options: "=",
            labelKey: "@",
            valueKey: "@",
            ngChange: "&",
            isSearch: "<",
            width: "<",
        },
        controller: [
            "$scope",
            "$timeout",
            "$element",
            "$compile",
            "$sce",
            function ($scope, $timeout, $element, $compile, $sce) {
                var vm = this;
                var content = null;
                var selectEle = $element.find(".modele-select")[0];
                vm.useOptions = [];
                vm.optionsOld = [];
                //设置默认值
                if (!vm.placeholder) vm.placeholder = "请选择";
                if (!vm.labelKey) vm.labelKey = "label";
                if (!vm.valueKey) vm.valueKey = "value";
                vm.searchText = "";
                vm.$onInit = function () {
                    init();
                }
                function init(){
                    var _option = [];
                    var isAllStrings = Array.isArray(vm.options) && vm.options.every(function(item) {
                        return typeof item === "string";
                    });
                    if (isAllStrings) { //如果传入['a','b','c']这种选项也可以支持
                        vm.options.forEach(function(item){
                            _option.push({
                                label:item,
                                value:item
                            })
                        })
                    }else{
                        _option = vm.options;
                    }
                    if (!_option) {
                        vm.useOptions = [];
                    }else{
                        vm.useOptions = angular.copy(_option);
                    }

                    vm.optionsOld = angular.copy(vm.useOptions);
                }
                //创建option节点
                function createOptionDom() {
                    var template = '<div class="modele-select-option" ng-show="vm.showOption"></div>';
                    var linkFn = $compile(template);
                    content = linkFn($scope);
                    // 插入到 body 元素下面
                    angular.element(document.body).append(content);

                    var templateMark = '<div class="modele-select-mark" ng-if="vm.showOption" ng-click="hide()"></div>';
                    var linkFn = $compile(templateMark);
                    // 插入到 body 元素下面
                    angular.element(document.body).append(linkFn($scope));

                }

                //下拉框是否展示
                vm.showOption = false;
                vm.selectWidth = selectEle.getBoundingClientRect().width;

                //监控options的变化
                $scope.$watch("vm.options.$resolved", function (newVal, oldVal) {
                    if (newVal) {
                        init();
                    }
                });

                $scope.$watch("vm.options", function (newVal, oldVal) {
                    if (newVal != oldVal) {
                        init();
                    }
                });
                //组装option
                vm.createOptions = function () {
                    if (!content) {
                        createOptionDom();
                    }
                    filterFn();
                    var optionDom = "";
                    // 如果vm.isSearch为true，添加搜索框
                    if (vm.isSearch) {
                        optionDom += '<div class="option-search-box">';
                        optionDom += '<input type="text" class="searchInput" ng-model="vm.searchText" placeholder="搜索" ng-change="searchInputChange($event)">';
                        optionDom += '<i class="iconfont icon-sousuo cursor-pointer" ng-click="onIconClick($event)"></i>';
                        optionDom += '</div>';
                    }
                    angular.forEach(vm.useOptions, function (item) {
                        if(item[vm.labelKey].indexOf("'")>-1){
                            item[vm.labelKey] = replaceSingleQuotes(item[vm.labelKey])
                        }
                        optionDom += '<div class="option-item" ng-show="' + item.optionIsShow + '" ng-click="selectOption($event)" data-value="' + item[vm.valueKey] + '" ng-bind-html="vm.getHighlightedText(' + "'" + item[vm.labelKey] + "'" + ', vm.searchText)"></div>';
                    });
                    var compiledOptionDom = $compile(optionDom)($scope);
                    angular.element(content).html(compiledOptionDom);
                    if (vm.showOption) {
                        vm.getOptionPlace();
                    }
                    $timeout(function(){
                        if (angular.element(content).find('.searchInput').length) {
                            angular.element(content).find('.searchInput')[0].focus();
                        }
                        var activeElements = angular.element(content).find('.active');
                        if (activeElements.length > 0) {
                            var activeElement = activeElements[0];
                            activeElement.scrollIntoView();
                            angular.element(content)[0].scrollTop -= 36;
                        }
                    },10)
                }
                //中文符号替换
                function replaceSingleQuotes(str) {
                    var count = 0;
                    return str.replace(/'/g, (match) => {
                        count++;
                        if (count % 2 === 1) {
                            return '‘';
                        } else {
                            return '’';
                        }
                    });
                }
                //获取选择框展示的名称
                vm.getShowName = function () {
                    if (vm.ngModel === "" || vm.ngModel === null) {
                        return '';
                    } else {
                        var selectIndex = vm.useOptions.findIndex(function (item) {
                            return item[vm.valueKey] == vm.ngModel;
                        })
                        if (selectIndex >= 0) {
                            //修改页面状态
                            $(content).find(".option-item").removeClass("active");
                            $(content).find(".option-item").eq(selectIndex).addClass("active");
                            return vm.useOptions[selectIndex][vm.labelKey];
                        }
                        return '';
                    }
                };

                //判断下拉框展示位置
                vm.getOptionPlace = function () {
                    if (!vm.showOption) return;
                    $timeout(function () {
                        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                        var windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
                        var selectInfo = selectEle.getBoundingClientRect();
                        var optionHeight = $(content)[0].getBoundingClientRect().height;
                        vm.selectWidth = selectInfo.width;
                        $(content).css({
                            width: vm.selectWidth + "px"
                        })
                        //判断右侧距离是否足够
                        if ($(content)[0].getBoundingClientRect().right >= windowWidth) {
                            $(content).css({
                                right: 0,
                                left: 'auto'
                            });
                        } else {
                            $(content).css({
                                left: selectInfo.left + "px",
                                right: 'auto'
                            });
                        }


                        //判断下方高度是否足够
                        if (windowHeight - selectInfo.bottom > optionHeight + 4) {
                            $(content).css({
                                top: selectInfo.bottom + 4 + "px",
                                bottom: "auto",
                                transform: "none"
                            });
                            return;
                        }
                        //判断上方高度是否足够
                        if (selectInfo.top > optionHeight + 4) {
                            $(content).css({
                                bottom: windowHeight - selectInfo.top + 4 + "px",
                                top: "auto",
                                transform: "none"
                            });
                            return;
                        }
                        //距离不够，居中显示
                        $(content).css({
                            top: "50%",
                            transform: "translate(0, -50%)"
                        });
                    });
                    $timeout(function(){
                        $(content).css({
                            zIndex: 9999,
                            // position:'relative'
                        });
                    },10)
                };
                //点击输入框，展示或隐藏下拉列表
                $scope.clickInput = function () {
                    if (vm.showOption) {
                        $scope.hide();
                    } else {
                        $scope.show();
                    }
                };
                $scope.show = function () {
                    if (!content) {
                        createOptionDom();
                    }
                    vm.createOptions();
                    $timeout(function () {
                        vm.showOption = true;
                        $(content).show();
                        vm.getOptionPlace();
                    });
                };
                $scope.hide = function () {
                    $timeout(function () {
                        vm.searchText = '';
                        vm.showOption = false;
                        $(content).hide();
                    });
                };
                // 判断光标是否在类名为"modele-select-option"的元素中
                function isCursorInModeleSelectOption() {
                    // 获取光标当前的坐标位置
                    var x = event.clientX;
                    var y = event.clientY;
                    if (!x || !y) {
                        return false
                    }
                    // 确保x和y的值在文档范围内
                    var maxX = document.documentElement.scrollWidth || document.body.scrollWidth;
                    var maxY = document.documentElement.scrollHeight || document.body.scrollHeight;
                    x = Math.min(Math.max(x, 0), maxX);
                    y = Math.min(Math.max(y, 0), maxY);

                    var cursorElement = document.elementFromPoint(x, y);
                    return cursorElement.closest(".modele-select-option") !== null;
                }
                //页面点击
                function docClick() {
                    if (vm.showOption && !isCursorInModeleSelectOption()) {
                        $scope.hide();
                    }
                }

                vm.resizeTimer = null;
                //屏幕大小变化或页面滚动
                function onResizeOrScroll() {
                    if (vm.showOption) {
                        if (!isCursorInModeleSelectOption()) {
                            $scope.hide();
                            return;
                        }
                        if (!vm.resizeTimer) {
                            vm.resizeTimer = setTimeout(function () {
                                //重新计算下拉框展示位置
                                vm.getOptionPlace();
                                vm.resizeTimer = null;
                            }, 100);
                        }
                    }
                }
                document.addEventListener("click", docClick);
                window.addEventListener("resize", onResizeOrScroll);
                window.addEventListener("scroll", onResizeOrScroll);
                window.addEventListener("mousewheel", onResizeOrScroll);

                vm.$onDestroy = function () {
                    //删除插入元素，取消监听事件
                    if (content) {
                        content.remove();
                    }
                    document.removeEventListener("click", docClick);
                    window.removeEventListener("resize", onResizeOrScroll);
                    window.removeEventListener("scroll", onResizeOrScroll);
                    window.removeEventListener("mousewheel", onResizeOrScroll);
                };

                $scope.searchInputChange = function (event) {
                    vm.createOptions();
                }

                // 点击iconfont图标触发的函数
                $scope.onIconClick = function (event) {
                    event.stopPropagation(); // 阻止事件冒泡
                    vm.createOptions();
                };

                function filterFn() {
                    var options = angular.copy(vm.optionsOld)
                    if (vm.searchText.trim() === '') {
                        options.forEach(function (item) {
                            item.optionIsShow = true;
                        })
                    } else {
                        var regex = new RegExp(vm.searchText, 'i');
                        options.forEach(function (item) {
                            if (regex.test(item[vm.labelKey])) {
                                item.optionIsShow = true;
                            } else {
                                item.optionIsShow = false;
                            }
                        });
                    }
                    vm.useOptions = angular.copy(options);
                }

                vm.getHighlightedText = function (text, searchText) {
                    if (!angular.isString(text)) {
                        return text;
                    }

                    if (!angular.isString(searchText) || searchText.trim() === '') {
                        return $sce.trustAsHtml(text);
                    }

                    var regex = new RegExp(searchText, 'gi');
                    var highlighted = text.replace(regex, function (match) {
                        return '<span class="highlight">' + match + '</span>';
                    });

                    return $sce.trustAsHtml(highlighted);
                };

                $scope.selectOption = function (e) {
                    vm.ngModel = $(e.target).attr('data-value');
                    $scope.hide();
                    $timeout(function () {
                        if (typeof vm.ngChange === "function") {
                            vm.ngChange();
                        }
                    }, 20);
                }

                $scope.removeSelect = function(e){
                    e.stopPropagation();
                    vm.ngModel = null;
                    $timeout(function () {
                        if (typeof vm.ngChange === "function") {
                            vm.ngChange();
                        }
                    }, 20);
                }
            }
        ]
    };
    angular.module("gwApp").component("selectView", ModeleSelect);
})();
