(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('SystemLogController', SystemLogController);

    SystemLogController.$inject = ['$scope','SystemLog','MessageService', 'ParseLinks', '$stateParams'];

    function SystemLogController ($scope,SystemLog,MessageService, ParseLinks, $stateParams) {
        var vm = this;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            sort: ['createdDate,DESC'],
            keyword:'',
            start:'',
            end:''
        };
        angular.element("#startDate").val(null);
        angular.element("#endDate").val(null);
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }

        init();
        function init() {
            vm.tableList = [];
            vm.tableData.start = angular.copy(angular.element("#startDate").val());
            vm.tableData.end = angular.copy(angular.element("#endDate").val());
            SystemLog.page(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };

        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                keyword:'',
                start:'',
                end:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        };
    }
})();
