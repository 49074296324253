(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderStatisticsController', OrderStatisticsController);

    OrderStatisticsController.$inject = ['$scope', '$state', 'MessageService','$location', 'SalesOrder', '$uibModal', 'ParseLinks','Product', '$localStorage','$http', '$stateParams', '$rootScope'];

    function OrderStatisticsController ($scope, $state, MessageService,$location, SalesOrder, $uibModal, ParseLinks, Product, $localStorage, $http, $stateParams, $rootScope) {
        var vm = this;
        vm.token = $localStorage.authenticationToken;
        vm.timeName = 'month';
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            productName: '',
            state: '',
            startDate: "",
            storeName:'',
            endDate: "",
            orderType:'Ordering',
            size:20
        };
        vm.backList = [
            {name:'全部', value: ""},
            {name:'未发货', value: "UnDelivered"},
            {name:'已发货', value: "Delivered"},
            {name:'已签收', value: "Received"}
        ];
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.typeName = '';
        vm.timeList = [
            {name:'昨日', value:'day'},
            {name:'最近7天', value:'week'},
            {name:'最近30天', value:'month'}
        ];
        vm.salesOrders = [
            {
                id:1,
                stockOrderNumber:1,
                operator:1,
            }
        ]
        $scope.getProductId =function(data){
            vm.tableData.productId =data ? data.id :'';
        };
        vm.storeList = SalesOrder.storeNames()
        console.log(vm.storeList);

        angular.element("#startDate").val($stateParams.startDate || $location.search().startDate || null);
        angular.element("#endDate").val($stateParams.endDate || $location.search().endDate || null);
        init();
        function init() {
            vm.tablePitch = '';
            $scope.select_all = false;
            $scope.checked =[];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'source':vm.tableData.source,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'refund':vm.tableData.refund,
                'keyword':vm.tableData.keyword,
                'productName':vm.tableData.productName,
            });
            SalesOrder.manufactureStats(vm.tableData, function(res, headers) {
                console.log(res);
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });

            //获取订单总数和总张数
            $scope.orderNumberTotal = 0;
            $scope.pageCountTotal = 0;
            SalesOrder.getNumByCondition(vm.tableData, function(res) {
                $scope.orderNumberTotal = res.total;
                $scope.pageCountTotal = res.pages == null ? 0 : res.pages;
            });
        };

        $scope.timeCb = function (time) {
            vm.timeName = time.value;
            angular.element("#endDate").val(GetDateStr(0));
            if(time.value == 'day'){
                angular.element("#startDate").val(GetDateStr(-1));
            }
            if(time.value == 'week'){
                angular.element("#startDate").val(GetDateStr(-7));
            }
            if(time.value == 'month'){
                angular.element("#startDate").val(null);
            }
            initData();
        };

        $scope.inquire = function () {
            vm.tableData.page = 0
            init();
        }
        function initData() {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            // if(vm.tableData.supId == ""){
            //     MessageService.error("供应商不能为空");
            //     return
            // }
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            var _startDate = new Date(vm.tableData.startDate);
            var _endDate = new Date(vm.tableData.endDate);
            var _mistiming = parseInt(_endDate - _startDate) / 1000 / 60 / 60 / 24;
            vm.timeName = "";
            if(GetDateStr(0) == vm.tableData.endDate){
                console.log(vm.tableData.endDate,_mistiming);
                if(_mistiming == 1){vm.timeName = "day";}
                if(_mistiming == 7){vm.timeName = "week";}
                if(_mistiming == 30){vm.timeName = "month";}
            }
        }

        //下载
        $scope.export = function () {
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            if(vm.tableData.startDate == ""){
                MessageService.error("开始时间不能为空");
                return
            }
            if(vm.tableData.endDate == ""){
                MessageService.error("结束时间不能为空");
                return
            }
            $http({
                url: 'api/sales_order/export',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        function getDate (datestr) {
            datestr = datestr.split(" ")[0];
            var temp = datestr.split("-");
            if (temp[1] === '01') {
                temp[0] = parseInt(temp[0],10) - 1;
                temp[1] = '12';
            } else {
                temp[1] = parseInt(temp[1],10) - 1;
            }
            //new Date()的月份入参实际都是当前值-1
            var date = new Date(temp[0], temp[1], temp[2]);
            return date;
        }
        function getDiffDate (start, end) {
            var startTime = getDate(start);
            var endTime = getDate(end);
            var dateArr = [];
            while ((endTime.getTime() - startTime.getTime()) > 0) {
                var year = startTime.getFullYear();
                var month = startTime.getMonth().toString().length === 1 ? "0" + (parseInt(startTime.getMonth().toString(),10) + 1) : (startTime.getMonth() + 1);
                var day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
                dateArr.push(year + "-" + month + "-" + day);
                startTime.setDate(startTime.getDate() + 1);
            }
            return dateArr;
        }
    }
})();
