(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AllocationController', AllocationController);

    AllocationController.$inject = ['$scope','Allocations','Depository','MessageService', 'ParseLinks', '$stateParams','Product','$uibModal'];

    function AllocationController ($scope,Allocations,Depository,MessageService, ParseLinks, $stateParams,Product,$uibModal) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.clear =clear;

        //type  accessories 辅料 / product 成品
        Depository.getAll({type: "product"},function(res){
            vm.categories = res.filter(function(item){return item.defaulted});
        });

        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            type: 'product',// 类型。accessories 辅料 / product 成品
            key:'',
            sort: ['createdDate,DESC'],
            startDate:'',
            endDate:'',
            inWarehouseId:'',
            outWarehouseId:''
        };
        angular.element("#startDate").val(null);
        angular.element("#endDate").val(null);
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }

        loadAll();
        function loadAll() {
            vm.allocations = [];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            Allocations.page(vm.tableData, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');

                vm.allocations = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            loadAll();
                        }
                    }
                });
            }, function (error) {

            });
        }

        $scope.search =function(){
            vm.tableData.page = 0;
            loadAll();
        };

        $scope.reset = function(){
            vm.tableData = {
                page: 0,
                size: 20,
                type: 'product',// 类型。accessories 辅料 / product 成品
                key:'',
                startDate:'',
                endDate:'',
                inWarehouseId:'',
                outWarehouseId:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            loadAll();
        };
        function clear(){
            vm.tableData.key = null;
            vm.tableData.page = 0;
            loadAll();
        }

        $scope.newAllocation = function(){
            $uibModal.open({
                templateUrl: 'app/finishedWarehouse/allocation/allocation-add.html',
                controller: 'AllocationAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result){
                //angular.forEach(vm.tableList, function (item) {
                //    item.checked = false;
                //});
                loadAll();
            });
        };




    }
})();
