(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchaseController', PurchaseController);

    PurchaseController.$inject = ['$scope', 'Principal', '$state', 'User', 'MessageService', 'PurchaseOrder', 'ParseLinks', '$uibModal', '$timeout', '$stateParams',
        'Supplier','Depository','DateUtils', '$localStorage','$http'];

    function PurchaseController ($scope, Principal, $state, User, MessageService, PurchaseOrder, ParseLinks, $uibModal, $timeout, $stateParams,
                                 Supplier,Depository,DateUtils, $localStorage, $http) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_PRODUCT').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;

        vm.isTableState = false;
        vm.pageSize = [
            {name:'10', value:10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.selection = [
            {name:'全部', value:''},
            {name:'待提交', value:'New'},
            {name:'待审核', value:'ToBeAudited'},
            {name:'未入库', value:'AlreadyIssued'},
            // {name:'部分入库', value:'Store'},
            {name:'已完成', value:'Finished'},
            {name:'已关闭', value:'Close'}
        ];
        vm.tableData = {
            page: Number($stateParams.page) || 0,
            size: 20,
            number:'',
            supplierId:'',
            warehouseId:'',
            sort: ['createdDate,DESC'],
            type:''
        };
        vm.suppliers = Supplier.getAll();
        Depository.getAll({},function(res){
            vm.warehouses = res.filter(function(item){return item.defaulted});
        });

        //获取选中的数组
        $scope.checked =[];
        $scope.selectOne = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.tableList.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.tableList.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
        };

        // 提交
        $scope.submission = function() {
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }
            var ids = '';
            for (var i = 0; i < $scope.checked.length; i++) {
                if ($scope.checked[i].state == 'New') {
                    ids += $scope.checked[i].id + ",";
                }else{
                    MessageService.error('请选择新建的采购单');
                    return;
                }
            }
            ids = ids.substr(0, ids.length - 1);
            PurchaseProductLine.changeState({ids: ids, state: 'ToBeAudited'}, function (data) {
                MessageService.success('采购订单提交成功！');
                init();
            }, function (error) {
                MessageService.error('采购订单提交失败！');
            });
        }

        $scope.compulsoryWarehousing = function() {
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }
            var ids = '';
            for (var i = 0; i < $scope.checked.length; i++) {
                if ($scope.checked[i].state == 'AlreadyIssued') {
                    ids += $scope.checked[i].id + ",";
                }
            }

            ids = ids.substr(0, ids.length - 1);
            PurchaseProductLine.changeState({ids: ids, state: 'compulsoryWarehousing'}, function (data) {
                MessageService.success('采购订单强制入库成功！');
                init();
            }, function (error) {
                MessageService.error('采购订单强制入库失败！');
            });
        }

        //新建
        $scope.newOrder = function(){
            $uibModal.open({
                templateUrl: 'app/purchase/purchaseOrder-add.html',
                controller: 'PurchaseOrderAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return null;
                    }
                }
            }).result.then(function(result){
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                init();
            });
        };


        //入库
        $scope.warehousing = function() {
            if ($scope.checked.length == 0) {
                MessageService.error('请选择列表项');
                return;
            }
            if ($scope.checked.length > 1) {
                MessageService.error('请选择一项添加入库数量');
                return;
            }
            if ($scope.checked[0].state != 'AlreadyIssued'){
                MessageService.error('请选择一项已下达的订购添加入库数量');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/purchase/warehousing.html',
                controller: 'WarehousingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return $scope.checked;
                    }
                }
            }).result.then(function(result){
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                init();
            });

        };

        $scope.toExamine = function() {
            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
                if (vm.purchaseOrderList[i].state == 'ToBeAudited'||vm.purchaseOrderList[i].state == 'ToBeRevised') {}
            }
            var _data = [];
            for (var i = 0; i < $scope.checked.length; i++) {
                if ($scope.checked[i].state == 'ToBeAudited' || $scope.checked[i].state == 'ToBeRevised') {
                    _data.push($scope.checked[i]);
                }else{
                    MessageService.error('请选择待审核或者待修订的采购单');
                    return;
                }
            }

            $uibModal.open({
                templateUrl: 'app/purchase/toExamine.html',
                controller: 'ToExamineController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return _data;
                    }
                }
            }).result.then(function(result){
                angular.forEach(vm.tableList, function (item) {
                    item.checked = false;
                });
                init();
            });
        }

        $scope.cancelledBtn = function() {
            if($scope.checked.length == 0){
                MessageService.error('请选择列表项');
                return;
            }

            var ids = '';
            for (var i = $scope.checked.length - 1; i >= 0; i--) {
                if ($scope.checked[i].state == 'AlreadyIssued') {
                    MessageService.error('已下达采购不能取消');
                    return;
                }
                ids += $scope.checked[i].id + ",";
            }
            ids = ids.substr(0, ids.length - 1);

            PurchaseProductLine.cancelled({ids:ids}, function(){
                MessageService.success('取消成功');
                init();
            }, function(){
                MessageService.error('取消失败');
            })
        }

        //导出
        $scope.export = function () {
            $http({
                url: 'api/purchaseOrder/exportStatistics?number='+ vm.tableData.number + '&available=' + false  + '&supplierId=' + vm.tableData.supplierId + '&warehouseId=' + vm.tableData.warehouseId + '&startDate=' + vm.tableData.startDate + '&endDate=' + vm.tableData.endDate + '&status=' + vm.tableData.type ,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "采购单" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        var _h = angular.element(".table-top").find(".table-state").height();
        if(_h > 40){
            vm.isTableState = true;
        }else {
            vm.isTableState = false;
        }

        init();
        function init() {
            vm.tableList = [];
            vm.tableData.startDate = angular.copy(angular.element("#startDate").val());
            vm.tableData.endDate = angular.copy(angular.element("#endDate").val());
            $scope.checked = [];
            $scope.select_all = false;
            var items = [];
            var _p = {
                items:items,
                page:vm.tableData.page,
                size:vm.tableData.size,
                sort:vm.tableData.sort,
            }
            if(vm.tableData.type){
                items.push({key:"status",op:"=",value:vm.tableData.type})
                // if(vm.tableData.type != 'Close'){
                //     items.push({key:"status",op:"=",value:vm.tableData.type})
                // }else{
                //     items.push({key:"cancelled",op:"=",value:true})
                //     _p.available = false;
                // }
            }
            if(vm.tableData.number){
                items.push({key:"number",op:"=",value:vm.tableData.number})
            }
            if(vm.tableData.supplierId){
                items.push({key:"supplier.id",op:"=",value:vm.tableData.supplierId})
            }
            if(vm.tableData.warehouseId){
                items.push({key:"warehouse.id",op:"=",value:vm.tableData.warehouseId})
            }
            if(vm.tableData.startDate){
                items.push({
                    "key": "createdDate",
                    "op": ">=",
                    "value": DateUtils.dayStart(vm.tableData.startDate)
                })
            }
            if(vm.tableData.endDate){
                items.push({
                    "key": "createdDate",
                    "op": "<=",
                    "value": DateUtils.dayEnd(vm.tableData.endDate)
                })
            }
            _p.items = items;
            var _p1 = {
                number:vm.tableData.number,
                supplierId:vm.tableData.supplierId,
                status:vm.tableData.type,
                warehouseId:vm.tableData.warehouseId,
                startDate:vm.tableData.startDate,
                endDate:vm.tableData.endDate,
                page:vm.tableData.page,
                size:vm.tableData.size,
                available:false,
                sort:vm.tableData.sort
            }
            PurchaseOrder.queryTable(_p1, function (res, headers) {
                console.log(res)
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.tableList = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {
                console.log(error)
            });
        }

        var nowTime = new Date().getTime();
        $scope.getWarnTime = function(date){
            var time = getDate(date).getTime();
            var dayNum = (time - nowTime) / (1000 * 60 * 60 * 24) - 1;
            dayNum = Math.ceil(dayNum);
            return dayNum;
        }

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }


        $scope.conditionSelect = function(data){
            vm.tableData.type = data.value;
            vm.tableData.page = 0;
            init();
        };

        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                page: Number($stateParams.page) || 0,
                size: 20,
                sort: ['createdDate,DESC'],
                type:''
            };
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            init();
        };

        $scope.tableStateBtn = function () {
            var _h = angular.element(".table-top").height();
            if(_h > 40){
                angular.element(".table-top").height("40");
                angular.element(".table-state-btn").removeClass("table-state-btnOn");
            }else {
                angular.element(".table-top").height("auto");
                angular.element(".table-state-btn").addClass("table-state-btnOn");
            }
        };

        window.onresize = function(){
            var _h = angular.element(".table-top").find(".table-state").height();
            $timeout(function () {
                if(_h > 40){
                    vm.isTableState = true;
                }else {
                    vm.isTableState = false;
                }
            })
        }
        $scope.purchaseOrderType = function (item){
            switch (item) {
                case 'New':
                    return {
                        text:'待提交',
                        color:'#333'
                    };
                case 'ToBeAudited':
                    return {
                        text:'待审核',
                        color:'#ffa037'
                    };
                case 'Close':
                    return {
                        text:'已关闭',
                        color:'#333'
                    };
                case 'AlreadyIssued':
                    return {
                        text:'未入库',
                        color:'#ff0000'
                    };
                case 'Store':
                    return {
                        text:'部分入库',
                        color:'#51de37'
                    };
                case 'Finished':
                    return {
                        text:'已完成',
                        color:'#005eff'
                    };
                case 'Examined':
                    return {
                        text:'审核通过',
                        color:'#333'
                    };
                default:
                    return {
                        text:item,
                        color:'#333'
                    };
            }
        }
    }

})();
