(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('InventoryTaskController', InventoryTaskController);

    InventoryTaskController.$inject = ['$scope','$state','Reserves','$uibModalInstance','MessageService','Inventory','msgdata'];

    function InventoryTaskController ($scope, $state,Reserves,$uibModalInstance,MessageService,Inventory,msgdata) {
        var vm = this;
        vm.inventory = msgdata;
        $scope.validate = function() {
            if (vm.num === null || vm.num === "") {
                MessageService.error("请输入调整数量");
                return false;
            };
            if(vm.num < 0){
                MessageService.error("数量不可为负数");
                return false;
            }
            if (vm.reason == null || vm.reason == "") {
                MessageService.error("请输入调整原因");
                return false;
            };
            return true;
        };

        $scope.saveInventory = function(){
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            var obj={
                skuName:vm.inventory.propertyConfig.sku,
                quantity:vm.inventory.availableQuantity,
                type:vm.num > vm.inventory.availableQuantity ?'in':'out',
                pageType:'product',
                num: vm.num > vm.inventory.availableQuantity ? vm.num - vm.inventory.availableQuantity : vm.inventory.availableQuantity - vm.num,
                warehouse:vm.inventory.warehouse,
                reason:vm.reason,
                propertyConfig: {id: vm.inventory.propertyConfig.id}
            };
            Inventory.create(obj,function (res) {
                MessageService.success("盘点调整新建成功!");
                $uibModalInstance.close();
            },function (error) {
                vm.isSaving = false;
                MessageService.error("盘点调整新建失败1！");
            });
        };

        $scope.cancelRecord = function(){
            $uibModalInstance.close();
        };

    }
})();
