(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderWarnController', OrderWarnController);

    OrderWarnController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder',
    '$uibModal', 'Supplier','Product', '$localStorage','$http', '$stateParams', '$rootScope', '$location'];

    function OrderWarnController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder,
        $uibModal, Supplier, Product, $localStorage, $http, $stateParams, $rootScope, $location) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.pageSize = [
            {name:'10', value: 10},
            {name:'15', value: 15},
            {name:'20', value: 20},
            {name:'30', value: 30},
            {name:'40', value: 40},
            {name:'50', value: 50}
        ];
        vm.stateList = [
            {name:'待分配', value:'ToBeAssigned'},
            {name:'已分配', value: 'Assigned'},
            {name:'生产中', value: 'Producing'},
            {name:'已发货', value: 'Delivered'}
        ];
        vm.conditionList = [
            {name:'分配预警', value: 'allocation'},
            {name:'生产预警', value: 'production'},
            {name:'发货预警', value: 'shipments'},
            {name:'揽件预警', value: 'collect'},
            {name:'签收预警', value: 'sign'},
            {name:'物流异常', value: 'logisticsAbnormal'}
        ];
        vm.condition = 'allocation';

        vm.predicate = 'createdDate';
        vm.resolve = false;

        vm.page = $stateParams.page || $location.search().page;
        vm.tableData = {
            sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')],
            page: Number(vm.page) || 0,
            size: 20,
            keyword: $stateParams.keyword || $location.search().keyword || '',
            state: 'distribution',
            startDate: $stateParams.startDate || $location.search().startDate || null,
            endDate: $stateParams.endDate || $location.search().endDate || null,
            back: $stateParams.back || $location.search().back || '',
            timeWarning: $stateParams.timeWarning || $location.search().timeWarning || '',
            replenishment: $stateParams.replenishment || $location.search().replenishment || '',
            refund: $stateParams.refund || $location.search().refund || '',
            supplierId: $stateParams.supplierId || $location.search().supplierId || '',
            productId: $stateParams.productId || $location.search().productId ||'',
            sku:$stateParams.sku || $location.search().sku ||'',
            orderType: 'Ordering'
        };
        vm.startDate = $stateParams.startDate || $location.search().startDate || null;
        vm.endDate = $stateParams.endDate || $location.search().endDate || null;
        angular.element("#startDate").val($stateParams.startDate || $location.search().startDate || null);
        angular.element("#endDate").val($stateParams.endDate || $location.search().endDate || null);
        Supplier.getAll("", function (res) {
            vm.suppliers = res;
            if(vm.tableData.supplierId != ''){
                for(var i=0; i<vm.suppliers.length; i++ ){
                    if(vm.suppliers[i].id == vm.tableData.supplierId){
                        vm.supplier = vm.suppliers[i];
                    }
                }
            }
        });
        Product.getAllByCategory({type: 'OrderingProduct'}, function (res) {
            vm.productList = res;
            if(vm.tableData.productId != ''){
                for(var i=0; i<vm.productList.length; i++ ){
                    if(vm.productList[i].id == vm.tableData.productId){
                        vm.product = vm.productList[i];
                        vm.skuList = vm.productList[i].propertyConfigs;
                    }
                }
            }
        });

        //获取选中的数组
        $scope.checked =[];
        $scope.tableTr = function(data){
            var index = $scope.checked.indexOf(data);
            if(index == -1 && data.checked){
                $scope.checked.push(data);
            }else if (!data.checked && index !== -1){
                $scope.checked.splice(index, 1);
            }
            if (vm.salesOrders.length === $scope.checked.length) {
                $scope.select_all = true;
            } else {
                $scope.select_all = false;
            }

            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        $scope.selectAll = function(){
            if($scope.select_all) {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = true;
                });
                $scope.checked = vm.salesOrders.map(function(value){
                    return value;
                })
            }else {
                angular.forEach(vm.salesOrders, function (item) {
                    item.checked = false;
                });
                $scope.checked = [];
            }
            vm.tablePitch = $scope.checked.length >0 ?true:false;
        };

        $scope.sort = function () {
            vm.tableData.sort = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            init();
        }

        $scope.conditionSelect = function(data){
            vm.condition = data;
            vm.tableData.page = 0;

            if(vm.condition === 'allocation'){
                vm.tableData.state = 'ToBeAssigned';
            }
            if(vm.condition === 'production'){
                vm.tableData.state = 'Assigned';
            }
            if(vm.condition === 'shipments'){
                vm.tableData.state = 'Producing';
            }
            if(vm.condition === 'collect'){
                vm.tableData.state = 'Delivered';
            }
            if(vm.condition === 'sign'){
                vm.tableData.state = 'Receiving';
            }
            if(vm.condition === 'logisticsAbnormal'){
                vm.tableData.state = 'Abnormal';
            }
            init()
        };

        $scope.conditionSelect('allocation');
        function init() {
            vm.tablePitch = '';
            $scope.select_all = false;
            $scope.checked =[];
            vm.tableData.startDate = angular.copy(new Date(angular.element("#startDate").val()));
            vm.tableData.endDate = angular.copy(new Date(angular.element("#endDate").val()));
            if(vm.condition === 'allocation'){

            }
            $location.search({
                'page':vm.tableData.page,
                'state':vm.tableData.state,
                'startDate':vm.tableData.startDate,
                'endDate':vm.tableData.endDate,
                'timeWarning':vm.tableData.timeWarning,
                'supplierId':vm.tableData.supplierId,
                'productId':vm.tableData.productId,
                'keyword':vm.tableData.keyword
            });
            var items = [{key:"cancelled",op:"=",value:false}];
            if(vm.tableData.keyword){items.push({key:"sourceOrderNumber,number",op:"like",value:vm.tableData.keyword})}
            if(vm.tableData.startDate){items.push({key:"createdDate",op:">",value:vm.tableData.startDate})}
            if(vm.tableData.endDate){items.push({key:"createdDate",op:"<=",value:vm.tableData.endDate})}
            if(vm.tableData.supplierId){items.push({key:"supplierId",op:"=",value:vm.tableData.supplierId})}
            if(vm.tableData.state){items.push({key:"status",op:"=",value:vm.tableData.state})}
            if(vm.condition === 'allocation'){
            }
            if(vm.condition === 'production'){
                items.push({key:"admeasureDate",op:"<",value:new Date(frontOneHour('yyyy-MM-dd hh:mm:ss',24))})
            }
            //
            if(vm.condition === 'shipments'){
                items.push({key:"admeasureDate",op:"<",value:new Date(frontOneHour('yyyy-MM-dd hh:mm:ss',24))})
            }
            if(vm.condition === 'collect'){
                items.push({key:"deliveryDate",op:"<",value:new Date(frontOneHour('yyyy-MM-dd hh:mm:ss',48))})
            }
            //
            if(vm.condition === 'sign'){
                items.push({key:"pickDate",op:"<",value:new Date(frontOneHour('yyyy-MM-dd hh:mm:ss',120))})
            }
            SalesOrder.byCondition({
                items:items,
                page: vm.tableData.page,
                size: vm.tableData.size,
                sort: vm.tableData.sort
            }, function(res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.queryCount = headers('X-Total-Count');
                vm.salesOrders = res;
                var pages = Math.ceil(vm.queryCount/vm.tableData.size); //得到总页数
                vm.pagecount = pages; //得到总页数
                //调用分页
                laypage({
                    cont: 'paging',
                    curr: (vm.tableData.page + 1), //初始化当前页
                    pages: pages,
                    skip: true, //是否开启跳页
                    first: false,
                    last: false,
                    limit: true,
                    jump: function(obj, first){
                        if (!first) {
                            vm.tableData.page = obj.curr - 1;
                            init();
                        }
                    }
                });
            }, function (error) {

            });

            //获取订单总数和总张数
            $scope.orderNumberTotal = 0;
            $scope.pageCountTotal = 0;
            SalesOrder.pageWarnCount2({
                items:items,
                page: vm.tableData.page,
                size: vm.tableData.size,
                sort: vm.tableData.sort
            }, function(res) {
                $scope.orderNumberTotal = res.count;
                $scope.pageCountTotal = res.pageCount;
            });
        };

        $scope.selectSupplier =function(data){
            if(data){
                vm.tableData.supplierId = data.id;
            }else {
                vm.tableData.supplierId = "";
            }
        };
        $scope.search = function(){
            vm.tableData.page = 0;
            init();
        };
        $scope.reset = function(){
            vm.tableData = {
                sort: ['createdDate,DESC'],
                page: 0,
                size: 20,
                state:'distribution',
                startDate: '',
                endDate: '',
                timeWarning:'',
                replenishment: '',
                refund: '',
                condition: '全部',
                supplierId: '',
                sku:'',
            };
            vm.startDate = "";
            vm.endDate = "";
            vm.supplier = "";
            vm.product = "";
            angular.element("#startDate").val("");
            angular.element("#endDate").val("");
            $scope.conditionSelect('allocation');
            localStorage.removeItem('tableData');
        };
        //查看订单
        $scope.orderLook = function () {
            if($scope.checked.length >1){
                MessageService.error('仅能查看一个订单，请选择！');
                return;
            }else{
                $state.go('orderDetails',{
                    id:$scope.checked[0].id,
                    page: vm.tableData.page,
                    state:vm.tableData.state,
                    startDate:vm.tableData.startDate,
                    endDate:vm.tableData.endDate,
                    timeWarning:vm.tableData.timeWarning,
                    supplierId:vm.tableData.supplierId,
                    productId:vm.tableData.productId,
                    keyword:vm.tableData.keyword
                });
            }
        };
        //客户备注
        $scope.orderRemark = function () {
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单 ！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-remark.html',
                    controller: 'OrderRemarkController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return
                        }]
                    }
                }).result.then(function (backData) {
                    // console.log(backData);
                });
            }
        };
        //补货订单
        $scope.orderReplenishment = function(){
            if($scope.checked.length >1){
                MessageService.error('只能选择一个订单！');
                return;
            }else {
                $uibModal.open({
                    templateUrl: 'app/order/order-replenishment.html',
                    controller: 'OrderReplenishmentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function () {
                            return $scope.checked[0]
                        }]
                    }
                }).result.then(function (backData) {
                    if (backData) {
                        init()
                    }
                });
            }
        };

        //选择物流公司
        $scope.orderExpress = function(){
            //如果是已发货状态则不能修改物流公司
            var flagExpress =false;
            angular.forEach($scope.checked,function(item){
                if(item.status =="Delivered"){
                    flagExpress =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('存在已发货的订单，不能修改物流公司，请重新选择！');
                return;
            }else{
                $uibModal.open({
                    templateUrl: 'app/order/order-express.html',
                    controller: 'OrderExpressController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        entity: [function() {
                            return $scope.checked
                        }]
                    }
                }).result.then(function (backData) {
                    if(backData){
                        init()
                    }
                });
            }
        };
        //修改
        $scope.modification = function(){
            var flagExpress =false;
            var isStatus =false;
            var isStatusProducing =false;
            angular.forEach($scope.checked,function(item, index){
                if(item.status !="Assigned" && item.status !="Producing" && item.status !="Delivered"){
                    flagExpress =true;
                    return;
                }
                if(index>0 && item.status != $scope.checked[index-1].status){
                    isStatus =true;
                    return;
                }
                if(item.status == "Producing" && $scope.checked.length>1){
                    isStatusProducing =true;
                    return;
                }
            });
            if(flagExpress){
                MessageService.error('只能修改已分配、生产中、已发货这三个状态的订单');
                return;
            }
            if(isStatus){
                MessageService.error('请选择相同状态下的订单');
                return;
            }
            if(isStatusProducing){
                MessageService.error('生产中的订单只能选择一个');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/order/order-modification.html',
                controller: 'OrderModificationController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return $scope.checked
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    init()
                }
            });
        }

        //导出
        $scope.orderExport = function () {
            $http({
                url: 'api/sales_order/exportData1Warn',
                method: "GET",
                params: vm.tableData,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = Date.now() + ".xls";
                a.setAttribute('download', '');
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from;
            $rootScope.previousParams = fromParams;
        });

        vm.makeupStatus = makeupStatus;
        function makeupStatus(item) {
            switch (item) {
                case "NoMakeup":
                    return "未拼版";
                case "Makeuped":
                    return "已拼版";
                case "InMakeup":
                    return "拼版中";
                case "NotRequiredMakeup":
                    return "不拼版";
                default:
                    return "没有对应的状态";
            }
        }
        //日期
        function GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate()+AddDayCount);
            var y = dd.getFullYear();
            var m = (dd.getMonth()+1) < 10 ? '0' + (dd.getMonth()+1) : dd.getMonth()+1;//获取当前月份的日期
            var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
            return y+"-"+m+"-"+d;
        }
        function frontOneHour (fmt,h,time) {
            if(time){
                var currentTime = new Date(new Date(time).getTime() - h * 60 * 60 * 1000)
            }else {
                var currentTime = new Date(new Date().getTime() - h * 60 * 60 * 1000)
            }
            console.log(currentTime) // Wed Jun 20 2018 16:12:12 GMT+0800 (中国标准时间)
            var o = {
                'M+': currentTime.getMonth() + 1, // 月份
                'd+': currentTime.getDate(), // 日
                'h+': currentTime.getHours(), // 小时
                'm+': currentTime.getMinutes(), // 分
                's+': currentTime.getSeconds(), // 秒
                'q+': Math.floor((currentTime.getMonth() + 3) / 3), // 季度
                'S': currentTime.getMilliseconds() // 毫秒
            }
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (currentTime.getFullYear() + '').substr(4 - RegExp.$1.length))
            for (var k in o) {
                if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
            return fmt
        }
    }
})();
