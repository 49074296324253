(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('MaterialsAddController', MaterialsAddController);

    MaterialsAddController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'MaterialTemplates', 'Items', 'ProductCategory'];

    function MaterialsAddController ($scope, Principal, LoginService, $state,User,MessageService,MaterialTemplates, Items, ProductCategory) {
        var vm = this;
        vm.data = {
            name: '',
            type: '',
            enable: true,
            description: '',
            productCategoryId: '',
            productCategoryName: '',
            items: []
        };
        vm.propertyList = [];
        vm.itemsList = Items.getAll();

        // accessories 辅料 / product 成品
        vm.typeList = [
            {name:'商品类', value: 'product'},
            {name:'辅材类', value: 'accessories'}
        ];

        //商品分类一级
        vm.superiorName = null;
        //商品品类
        vm.productCategoryList = [];

        $scope.typeSelect = function(value){
            if(!value){
                vm.productCategoryList = [];
            }else {
                vm.productCategoryList = ProductCategory.getAll({type: value, subordinate: "0"});
            }
        };

        //商品品类选择
        $scope.productCategorySelect = function(){
            vm.superiorName = null;
            for(var i=0; i<vm.productCategoryList.length; i++){
                if(vm.productCategoryList[i].id == vm.data.productCategoryId){
                    vm.data.productCategoryName = vm.productCategoryList[i].name;
                    if(vm.productCategoryList[i].superiorId){
                        vm.superiorName = vm.productCategoryList[i].superiorName;
                    }
                }
            }
        }

        //属性删除
        $scope.propertyDelete = function(index){
            vm.propertyList.splice(index,1);
        };
        //属性新增
        $scope.propertyAdd = function(){
            vm.propertyList.push({
                property: ""
            });
        };

        $scope.save = function () {
            if(!vm.data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(vm.data.name.length >20){
                MessageService.error("名称不能大于20位");
                return;
            }
            if(!vm.data.type){
                MessageService.error("类型不能为空");
                return;
            }
            if(!vm.data.productCategoryId){
                MessageService.error("商品品类不能为空");
                return;
            }
            if(vm.data.description.length >50){
                MessageService.error("描述不能大于50位");
                return;
            }
            for(var i=0; i<vm.propertyList.length; i++){
                if(vm.propertyList[i].property == ""){
                    MessageService.error("属性不能为空");
                    return;
                }
                var _property = vm.itemsList.find(function(itm){
                    return itm.id == vm.propertyList[i].property
                })
                vm.data.items.push(_property)
            }

            vm.isSaving = true;
            MaterialTemplates.add(vm.data, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("添加成功");
                $state.go("materials");
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("添加失败，请稍后重试！");
            }
        };
    }
})();
