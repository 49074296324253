(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('TagDetailController', TagDetailController);

    TagDetailController.$inject = ['$scope', '$state','MessageService', 'MetaTags', 'entity', '$stateParams','LocationProvice', 'LocationCity', 'LocationDistrict','Supplier','Qinius','$q','$sce'];

    function TagDetailController ($scope,$state,MessageService,MetaTags, entity, $stateParams,LocationProvice, LocationCity, LocationDistrict,Supplier,Qinius, $q,$sce) {
        var vm = this;
        vm.ratio = 0;
        js_getDPI();
        if(!$stateParams.id){
            vm.data = {
                tags:[],
                width:100,
                height:100
            };
            initTags();
        }else {
            vm.data = entity;
            $q.all([vm.data.$promise]).then(function () {
                ratioInfo();
                initTags();
            })
        }
        function initTags() {
            var _tags = [];
            // _tags.push(getTag('altek'))
            _tags.push(getTag('articleName'))
            _tags.push(getTag('barCode'))
            // _tags.push(getTag('customer'))
            _tags.push(getTag('orderNumber'))
            _tags.push(getTag('phone'))
            _tags.push(getTag('productName'))
            _tags.push(getTag('quantity'))
            _tags.push(getTag('recipients'))
            _tags.push(getTag('sourceOrderNumber'))
            _tags.push(getTag('storeName'))
            vm.data.tags.forEach(function(tag){
                tag.enabled = true;
                tag.type1= 'enable' + tag.type.charAt(0).toUpperCase() + tag.type.slice(1);
                if(!tag.fontsize){tag.fontsize = 9}
                if(!tag.fontColor){tag.fontColor = "#000000"}
                tag.name = getName(tag.type);
                _tags.forEach(function(tag1,index){
                    if(tag.type == tag1.type){
                        _tags[index] = angular.copy(tag);
                    }
                })
            })
            vm.data.tags = _tags;
            console.log(vm.data.tags)
        }
        function getTag(type){
            var _data = {
                fontColor: "#000000", 
                text: true,
                x: 0,
                y: 0, 
                enabled: false, 
                fontsize: 9, 
                bold: false, 
                type:type, 
                type1: 'enable' + type.charAt(0).toUpperCase() + type.slice(1), 
                width: 100, 
                height: 8, 
                name: getName(type)
            }
            if(type == 'altek'){ _data.width = 19; _data.height = 4; _data.name = '客户简介' };
            if(type == 'customer'){  _data.width = 37; _data.height = 4; _data.name = '客户全名' };
            if(type == 'recipients'){  _data.width = 11; _data.height = 4; _data.name = '收件人' };
            if(type == 'quantity'){  _data.width = 9; _data.height = 4; _data.name = '份数' };
            if(type == 'productName'){  _data.width = 45; _data.height = 4; _data.name = '商品名称'};
            if(type == 'barCode'){  _data.width = 40; _data.height = 14; _data.name = '来源单号条码', _data.img=true};
            if(type == 'sourceOrderNumber'){  _data.width = 18; _data.height = 4; _data.name = '来源单号'};
            if(type == 'phone'){  _data.width = 18; _data.height = 4; _data.name = '收件人电话'};
            if(type == 'articleName'){  _data.width = 18; _data.height = 4; _data.name = '作品名'};
            if(type == 'storeName'){  _data.width = 18; _data.height = 4; _data.name = '店铺名称'};
            if(type == 'orderNumber'){  _data.width = 18; _data.height = 4; _data.name = '订单号'};
            return _data
        }
        $scope.getName = getName;
        function getName(type){
            if(type == 'altek'){ return '客户简介' };
            if(type == 'customer'){  return '客户全名' };
            if(type == 'recipients'){  return '收件人' };
            if(type == 'quantity'){ return '份数' };
            if(type == 'productName'){ return '商品名称'};
            if(type == 'barCode'){ return '来源单号条码'};
            if(type == 'sourceOrderNumber'){ return '来源单号'};
            if(type == 'phone'){ return '收件人电话'};
            if(type == 'articleName'){ return '作品名'};
            if(type == 'storeName'){ return '店铺名称'};
            if(type == 'orderNumber'){ return '订单号'};
        }
        Supplier.getAll({}, function (res) {
            vm.suppliers =res.filter(function(item){return item.enable == true;});
        })

        $scope.$watch("vm.data.width",function(newValue,oldValue){
            if(newValue > 0){
                ratioInfo()
            }
        })
        $scope.$watch("vm.data.height",function(newValue,oldValue){
            if(newValue > 0){
                ratioInfo()
            }
        })
        function ratioInfo(){
            if(vm.data.width <= 0 && vm.data.height <= 0){
                return
            }
            vm.windowWidth = 500;
            vm.windowHeight = 500;
            var ratio = vm.data.width / vm.data.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            if (ratio >= ratio1) {
                vm.ratio = vm.windowWidth / vm.data.width;
            } else {
                vm.ratio = vm.windowHeight / vm.data.height;
            }
        }

        vm.isDown = false;
        $scope.tagdown = function ($event, item) {
            fn($event)
            vm.tagChartlet = {};
            vm.tagChartlet.x = $event.clientX;
            vm.tagChartlet.y = $event.clientY;
            vm.tagChartlet.l = angular.copy(item.x * vm.ratio);
            vm.tagChartlet.t = angular.copy(item.y * vm.ratio);
            vm.tagChartlet.r = angular.copy((vm.data.width - item.width) * vm.ratio);
            vm.tagChartlet.b = angular.copy((vm.data.height - item.height) * vm.ratio);
            vm.isDown = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (!vm.isDown) {
                    return;
                }
                //获取x和y
                var nx = e.clientX;
                var ny = e.clientY;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - (vm.tagChartlet.x - vm.tagChartlet.l);
                var nt = ny - (vm.tagChartlet.y - vm.tagChartlet.t);
                if(nl<0){ nl = 0};
                if(nl > vm.tagChartlet.r){ nl = vm.tagChartlet.r};
                if(nt < 0){ nt = 0};
                if(nt > vm.tagChartlet.b){ nt = vm.tagChartlet.b};

                item.x = nl / vm.ratio;
                item.y = nt / vm.ratio;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function (e) {
                vm.isDown = false;
                $event.stopPropagation();
            }
        }
        $scope.save = function () {
            var _data = angular.copy(vm.data);
            if(!_data.name){
                MessageService.error("名称不能为空");
                return;
            }
            if(!_data.supplierId){
                MessageService.error("供应商不能为空");
                return;
            }
            if(!_data.width){
                MessageService.error("尺寸宽不能为空");
                return;
            }
            if(!_data.height){
                MessageService.error("尺寸高不能为空");
                return;
            }
            _data.tags = [];
            vm.data.tags.forEach(function (item) {
                _data[item.type1] = false;
                if(item.enabled){
                    _data[item.type1] = true;
                    _data.tags.push(item);
                }
            });
            vm.isSaving = true;
            if(!$stateParams.id){
                MetaTags.add(_data, onSaveSuccess, onSaveError);
            }else {
                MetaTags.update(_data, onSaveSuccess, onSaveError);
            }
            function onSaveSuccess (result) {
                vm.isSaving = false;
                MessageService.success("成功");
                $state.go("tag",{page: $stateParams.page});
            }

            function onSaveError () {
                vm.isSaving = false;
                MessageService.error("失败，请稍后重试！");
            }
        };
        //返回
        $scope.goBack = function () {
            $state.go("tag",{page: $stateParams.page});
        };


        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            } else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            return arrDPI;
        }
        //cm转px
        function cmTransitionPx(item) {
            return parseFloat((item * vm.windowDpi / 25.4 * 10).toFixed(2));
        }

        //px转cm
        function pxTransitionCm(item) {
            return parseFloat((item * 25.4 / vm.windowDpi / 10).toFixed(2));
        }
        //mm转px
        function mmTransitionPx(item) {
            return parseFloat((item * vm.windowDpi / 25.4));
        }

        //px转mm
        function pxTransitionMm(item) {
            return parseFloat((item * 25.4 / vm.windowDpi));
        }
        var a = null;
        $scope.fongsizeChange = function(item){
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                if(item){
                    if(!item.fontsize || item.fontsize<9){
                        item.fontsize = 9;
                    }
                }
                $scope.$apply();
            },1000)
        }
    }
})();
