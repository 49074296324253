(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OrderReplenishmentController', OrderReplenishmentController);

    OrderReplenishmentController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService', 'ParseLinks', 'SalesOrder','$uibModalInstance', 'entity', 'LocationCity', 'LocationProvice', 'LocationDistrict','$location','ReplenishmentReason','$localStorage','OrderPack'];

    function OrderReplenishmentController ($scope, Principal, LoginService, $state,User,MessageService, ParseLinks, SalesOrder, $uibModalInstance, entity, LocationCity, LocationProvice, LocationDistrict,$location,ReplenishmentReason,$localStorage,OrderPack) {
        var vm = this;
        vm.order = entity;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.flag = true;
        vm.token = $localStorage.authenticationToken;
        vm.fileResult = "";
        vm.reupload = false;
        vm.makeup = false;
        vm.isSaving = false;

        vm.replenishmentReasonId = "";
        vm.quantity = entity.quantity || 1;
        vm.justifyFile = "";
        vm.remarks = "";
        SalesOrder.get({id : vm.order.id}, function (res) {
            vm.sourceOrderNumber = res.sourceOrderNumber;
            OrderPack.get({id: res.orderPackId}, function (res1) {
                // if(res1.orderLocation){
                    vm._address = res1.orderLocation ? res1.orderLocation.address.split("-") : "";
                    vm.address = vm._address[3] ? vm._address[3] : "";
                    LocationProvice.query({},function (data) {
                        vm.provinces =  data;
                        if(vm._address[0]){
                            for(var i=0; i<data.length; i++){
                                if(vm._address[0] == data[i].name){
                                    vm.province = data[i];
                                    changeProvince()
                                    break;
                                }
                            }
                        }
                    });
                // }
                var _salesOrders = [];
                for(var i=0; i<res1.salesOrders.length; i++){
                    if(res1.salesOrders[i].id == vm.order.id){
                        res1.salesOrders[i].reupload = false;
                        _salesOrders.push(res1.salesOrders[i]);
                        break;
                    }
                }

                vm.orderData = {
                    id:res1.id,
                    number:res.number,
                    status:res.status,
                    salesOrders: _salesOrders,
                    merchantNumber: res1.merchantNumber,
                    settleAmount: res1.settleAmount,
                    orderSource: res1.orderSource,
                    orderLocation: {
                        contact:!res1.orderLocation ? "" : res1.orderLocation.contact,
                        phone:!res1.orderLocation ? "" : res1.orderLocation.phone,
                        address:!res1.orderLocation ? "" : res1.orderLocation.address,
                        serviceId:!res1.orderLocation ? "" : res1.orderLocation.serviceId,
                    }
                };
                vm.orderData.salesOrders.number = res.number;
                vm.orderData.salesOrders.sourceOrderNumber = res.sourceOrderNumber;
            });
        }, function (error) {

        });

        ReplenishmentReason.getAll({},function(res){
            vm.reasonList = res.filter(function(item){return item.enable});
        });

        function changeProvince() {
            if(vm.province){
                LocationCity.findByProvince({provinceId:vm.province.id},function (data) {
                    vm.citys = data;
                    vm.districts = [];
                    if(vm._address[1]){
                        for(var i=0; i<data.length; i++){
                            if(vm._address[1] == data[i].name){
                                vm.city = data[i];
                                changeCity();
                                break;
                            }
                        }
                    }
                });
            }
        }

        function changeCity() {
            if (vm.city == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: vm.city.id},function (data) {
                vm.districts = data;
                if(vm._address[2]){
                    for(var i=0; i<data.length; i++){
                        if(vm._address[2] == data[i].name){
                            vm.district = data[i];
                            break;
                        }
                    }
                }
            });
        }

        window.upladFile1 = function($event) {
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'pdf' && ext != 'zip' && ext != 'rar'){
                angular.element("#file").val("");
                MessageService.error("只能上传.zip  .pdf类型的文件!");
                return;
            }
            var url =  $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/sales_order/uploadFile"; // 接收上传文件的后台地址

            var formData = new FormData();
            formData.append('file', fileObj);
            formData.append('number', vm.orderData.number);

            $.ajax({
                url: url,
                type: "POST",
                data: formData,
                enctype: 'multipart/form-data',
                contentType: false,
                processData: false,
                cache: false,
                async: true,
                timeout: 100000,
                headers: {
                    Authorization: "Bearer " + vm.token
                },
                xhr: function() {        //ajax进度条，直接拿过去就可以用
                    var xhr = $.ajaxSettings.xhr();
                    if (xhr.upload) {
                        xhr.upload.addEventListener("progress", function (e) {
                            var loaded = e.loaded; //已经上传大小情况
                            var tot = e.total; //附件总大小
                            var per = Math.floor(100 * loaded / tot); //已经上传的百分比
                            $('#progress-bar').css('width', per+'%');   //这里指的是进度条的宽度等于完成的百分比
                        }, false);
                        return xhr;
                    }
                },
                success: function (data) {
                    MessageService.success("文件上传成功");
                    $('#progress-bar').css('width', '0%');
                    vm.justifyFile = data.path;
                    vm.orderData.salesOrders.forEach(function (item) {
                        item.justifyFile = data.path;
                        item.reupload = true;
                    })
                    $scope.$apply();    // 数据更新
                },
                error: function (error) {
                    angular.element("#file").val("");
                    MessageService.error("文件上传失败，请稍后重试！");
                    $('#progress-bar').css('width', '0%');
                }
            });
        };


        //文件上传
        window.uploadFile = function($event) {
            var ext,idx;
            var fileObj = $event.target.files[0]; // js 获取文件对象
            idx = fileObj.name.lastIndexOf(".");
            ext = fileObj.name.substr(idx+1).toUpperCase();
            ext = ext.toLowerCase();
            if (ext != 'pdf' && ext != 'zip'){
                angular.element("#file").val("");
                MessageService.error("只能上传.pdf/.zip类型的文件!");
                return;
            }

            if (fileObj.size > 1024 * 1024 * 1024 * 2) {
                MessageService.error("文件不能大于2GB");
                return;
            }

            checkIfUploaded(fileObj, ext);
        };

        function checkIfUploaded (file, ext) {
            var reader = new FileReader();
            // 用来计算fileMd5值的file内容
            var fileToCountMD5 = file;

            if (file.size > 1024*1024*50) {
                var fileStart = file.slice(0, 1024 * 1024);
                var fileEnd = file.slice(file.size - 1024 * 1024, file.size);
                fileToCountMD5 = new Blob([fileStart,fileEnd], { type: "text/plain" });
            }
            reader.readAsBinaryString(fileToCountMD5);

            //绑定读取失败事件
            reader.onerror = function() {
                MessageService.error("文件读取失败");
            };

            //绑定读取成功事件
            reader.onload = function() {
                //需要引入计算MD5的jar包
                var fileMd5 = hex_md5(reader.result);

                var form = new FormData();
                form.append("fileMd5", fileMd5);
                form.append("fileSize", file.size);

                //校验是否上传过该文件，返回上传进度
                console.log("isFileExist");
                $.ajax({
                    url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/isFileExist",
                    type: "POST",
                    data: form,
                    async: true,        //异步
                    processData: false,  //很重要，告诉jquery不要对form进行处理
                    contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                    success: function(data) {
                        if (data.success) {
                            //文件已上传
                            MessageService.success("上传成功！");
                        } else {
                            //文件未上传或者部分上传
                            uploadBySplit(file, fileMd5, 0, ext);
                        }
                    },
                    error: function() {
                        MessageService.error("服务器出错!");
                    }
                });
            }
        }

        //分片上传
        function uploadBySplit(file, fileMd5, i, ext, index_file) {
            var splitSize = 1024 * 1024 * 20;   //分片大小20M  -- 20M为一段
            var size = file.size;//总大小
            var splitCount = Math.ceil(size / splitSize);  //总片数
            console.log("分成"+splitCount+"份文件片");

            if (i == splitCount) {
                MessageService.success("文件上传成功！");
                return;
            }

            //计算每一片的起始与结束位置
            var start = i * splitSize;
            var end = Math.min(size, start + splitSize);
            var fileData = file.slice(start,end);
            var reader = new FileReader();
            reader.onload = function(){
                var md5 = hex_md5(reader.result);

                var form = new FormData();
                form.append("fileMd5", fileMd5);
                form.append("size", size);  //总大小
                form.append("totalStr", splitCount);   //总片数
                form.append("indexStr", i);    //当前是第几片
                form.append("md5", md5);    //分片md5

                //判断分片是否上传
                $.ajax({
                    url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/isFileSplitExist",
                    type: "POST",
                    data: form,
                    async: true,        //异步
                    processData: false,  //很重要，告诉jquery不要对form进行处理
                    contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                    success: function(data){
                        if (data.code == 1) {//已上传
                            //上传进度
                            var process = Math.round(end/size*100)+"%";
                            i++;
                            uploadBySplit(file, fileMd5, i, ext);    // 循环上传
                        } else { //未上传
                            form.append("fileData", fileData);
                            form.append("ext", ext);    // 文件后缀
                            //上传分片
                            $.ajax({
                                url: $location.protocol() + '://' + $location.host() + ':' + $location.port() +  "/api/scm-file/upload",
                                type: "POST",
                                data: form,
                                async: true,        //异步
                                processData: false,  //很重要，告诉jquery不要对form进行处理
                                contentType: false,  //很重要，指定为false才能形成正确的Content-Type
                                success: function(data) {
                                    if (data.state == 200) {
                                        //上传进度
                                        $('#progress-bar').css('width', (i / splitCount * 100)  +'%');
                                        i++;
                                        uploadBySplit(file, fileMd5, i, ext);
                                    } else if (data.state == 300) {
                                        $('#progress-bar').css('width', '0%');
                                        vm.justifyFile = data.message;
                                        vm.orderData.salesOrders.forEach(function (item) {
                                            item.justifyFile = data.message;
                                            item.reupload = true;
                                        })
                                        $scope.$apply();
                                    } else {
                                        $('#progress-bar').css('width', '0%');
                                        MessageService.error("分片上传失败");
                                    }
                                },
                                error: function(XMLHttpRequest, textStatus, errorThrown) {
                                    MessageService.error("服务器出错!");
                                }
                            });
                        }
                    },

                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        MessageService.error("服务器出错!");
                    }
                });
            }
            reader.readAsBinaryString(fileData);
        }

        $scope.modalHide = function(){
            $uibModalInstance.close(false);
        };

        //保存
        $scope.save = function () {
            if(vm.replenishmentReasonId == "" || vm.replenishmentReasonId == null){
                MessageService.error("请选择补货原因");
                return;
            }

            for (var i = vm.reasonList.length - 1; i >= 0; i--) {
                vm.orderData.salesOrders.forEach(function (item) {
                    if (vm.reasonList[i].id == vm.replenishmentReasonId) {
                        item.replenishmentReason = vm.reasonList[i].reason;
                        item.replenishmentReasonId = vm.replenishmentReasonId;
                    }
                })
            }

            if(!vm.quantity || vm.quantity < 1){
                MessageService.error("补货份数不得小于1");
                return;
            }
            if (vm.quantity > vm.order.quantity) {
                MessageService.error("补货份数不得大于订单份数");
                return;
            }
            if(vm.orderData.orderLocation.contact == "" || vm.orderData.orderLocation.contact == null){
                MessageService.error("姓名不能为空");
                return;
            }
            if(vm.orderData.orderLocation.phone == "" || vm.orderData.orderLocation.phone == null){
                MessageService.error("联系电话不能为空");
                return;
            }
            if(vm.province == "" || vm.city == "" || vm.district == ""){
                MessageService.error("收货地址不能为空");
                return;
            }
            if(vm.address == "" || vm.city == null){
                MessageService.error("详细地址不能为空");
                return;
            }

            vm.orderData.orderLocation.address = vm.province.name + '-' + vm.city.name + '-' + vm.district.name + '-' + vm.address;
            // console.log(vm.orderData);

            vm.orderData.salesOrders.forEach(function (item) {
                if (vm.makeup) {
                    item.makeupStatus = 'NoMakeup';
                } else {
                    item.makeupStatus = 'NotRequiredMakeup';
                }
                item.remarks = vm.remarks;
                item.replenishment = true;
                item.replenishmentOrders = [{
                    remarks:vm.remarks,
                    replenishmentReasonId:item.replenishmentReasonId,
                    replenishmentReason:item.replenishmentReason,
                    quantity: vm.quantity
                }]
            })
            vm.isSaving = true;
            OrderPack.replenishment(vm.orderData, function (res) {
                vm.isSaving = false;
                MessageService.success("补单成功");
                $uibModalInstance.close(true);
            }, function (error) {
                vm.isSaving = false;
                MessageService.error("补单失败");
            });
        }
    }
})();
