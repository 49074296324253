(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('SalesOrder', SalesOrder)
        .factory('OrderPack', OrderPack)
        .factory('PayOrders', PayOrders);

    SalesOrder.$inject = ['$resource'];
    OrderPack.$inject = ['$resource'];
    PayOrders.$inject = ['$resource'];

    function SalesOrder ($resource) {
        var resourceUrl =  'api/sales_order/:id';

        return $resource(resourceUrl, {}, {
            'updateOrder':{
                method: 'POST',
                params: {
                    id: 'updateOrder'
                }
            },
            'updateOrderBatch':{
                method: 'POST',
                isArray: true,
                params: {
                    id: 'updateOrderBatch'
                }
            },
            'getHotLinkData':{
                method: 'GET',
                params: {
                    id: 'getHotLinkData'
                }
            },
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'manufactureStats': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'manufactureStats'
                }
            },
            'getNumByCondition': {
                method: 'GET',
                params: {
                    id: 'getNumByCondition'
                }
            },
            'byCondition': {
                method: 'POST',
                isArray: true,
                params: {
                    id: 'byCondition'
                }
            },
            'pageWarnCount2': {
                method: 'POST',
                params: {
                    id: 'pageWarnCount2'
                }
            },
            'getReplenishData2': {
                method: 'GET',
                params: {
                    id: 'getReplenishData2'
                }
            },
            'getReplenishData': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'getReplenishData'
                }
            },
            'getData4Supplier': {
                method: 'GET',
                params: {
                    id: 'getData4Supplier'
                }
            },
            'getData4SupplierPage': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'getData4Supplier/page'
                }
            },
            'getByOrderMakeupFileId': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'getByOrderMakeupFileId'
                }
            },
            'allot': {
                method: 'GET',
                params: {
                    id: 'allot'
                }
            },
            'noAllot': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'no_allot'
                }
            },
            'noAllotMakeUpId': {
                method: 'GET',
                params: {
                    id: 'no_allot_make_up_id'
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'exportData1': {
                method: 'GET',
                params: {
                    id: 'exportData1'
                }
            },
            'exportData2': {
                method: 'GET',
                params: {
                    id: 'exportData2'
                }
            },
            'replenishment': {
                method: 'POST',
                params: {
                    id: 'replenishment'
                }
            },
            'delete':{
                method:'DELETE',
                params: {
                    id: 'id'
                }
            },
            'getOrderTask':{
                method:'GET',
                isArray: true,
                params: {
                    id: 'getOrderTask'
                }
            },
            'taskAssigned':{
                method:'GET',
                params: {
                    id: 'taskAssigned'
                }
            },
            'addRemarks':{
                method:'GET',
                params: {
                    id: 'addRemarks'
                }
            },
            'appointLogisticsCompany':{
                method:'GET',
                params: {
                    id: 'appointLogisticsCompany'
                }
            },
            'pageWarn': {
                method: 'GET',
                params: {
                    id: 'pageWarn'
                },
                isArray: true
            },
            'pageWarnCount': {
                method: 'GET',
                params: {
                    id: 'pageWarnCount'
                }
            },
            'updateLocation': {
                method: 'POST',
                params: {
                    id: 'updateLocation'
                }
            },
            'getAllStoreName': {
                method: 'GET',
                params: {
                    id: 'getAllStoreName'
                },
                isArray: true
            },
            'getData4Channel': {
                method: 'GET',
                params: {
                    id: 'getData4Channel'
                }
            },
            'getData4ChannelPage': {
                method: 'GET',
                params: {
                    id: 'getData4Channel/page'
                },
                isArray: true
            },
            'getData4DowmloadPage': {
                method: 'GET',
                params: {
                    id: 'getData4Dowmload/page'
                },
                isArray: true
            },
            'downloadData4Dowmload': {
                method: 'GET',
                params: {
                    id: 'downloadData4Dowmload'
                }
            },
            'exportReplenishData': {
                method: 'GET',
                params: {
                    id: 'exportReplenishData'
                }
            },
            'getHomePageData': {
                method: 'GET',
                params: {
                    id: 'getHomePageData'
                }
            },
            'storeNames': {
                method: 'GET',
                params: {
                    id: 'storeNames'
                },
                isArray: true
            }
        });
    }
    function OrderPack ($resource) {
        var resourceUrl =  'api/order_pack/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'replenishment': {
                method: 'POST',
                params: {
                    id: 'replenishment'
                }
            },
            'updateOrderLocation': {
                method: 'POST',
                params: {
                    id: 'updateOrderLocation'
                }
            },
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    id: 'page'
                }
            },
            'fetchReserve': {
                method: 'GET',
                params: {
                    path: 'getReserve'
                }
            },
            'delivery': {
                method: 'POST',
                params: {
                    id: 'delivery'
                }
            },
            'deleteOrder': {
                method: 'DELETE'
            }
        });
    }

    function PayOrders ($resource) {
        var resourceUrl =  'api/pay-orders/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'replenishment': {
                method: 'POST',
                params: {
                    id: 'replenishment'
                }
            },
            'updateOrderLocation': {
                method: 'POST',
                params: {
                    id: 'updateOrderLocation'
                }
            }
        });
    }
})();
